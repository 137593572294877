import React from 'react';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';

import homebanner from '../../images/landing/homebannerbg.jpg';
import './privacystyles.scss'
import useFirestore from '../../modules/flamelink-module';
import _ from 'lodash'

export default function PrivacyPolicy() {
    const docs = useFirestore('fl_content', 'policies');
    return (
        <Layout>
            <SEO title="Privacy Policy" description="" />
            <section className="privacy_banner">
                <img src={homebanner} alt="Home Banner" className="homeBannerImg" />
                <Container>
                    <div className="bannerCont">
                        <Grid container>
                            <Grid item xs="12" sm={6} >
                                <h2>PRIVACY POLICY</h2>
                            </Grid>
                            <Grid item xs="12" sm={6}></Grid>
                        </Grid>
                    </div>
                </Container>


            </section>
            <Container className="content">
                {
                    !_.isEmpty(docs) && <div className="justify-content-between blog-main-text" dangerouslySetInnerHTML={{ __html: docs[1].content }}></div>
                }
            </Container>
        </Layout>
    );
}
